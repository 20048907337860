// generate a separator component which can have title on the center

import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cls } from "~/old-app/utils/helpers/cls";

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { orientation = "horizontal", decorative, title, className, ...props },
    ref
  ) => {
    if (props.children === undefined) {
      return (
        <SeparatorPrimitive.Root
          ref={ref}
          decorative={decorative}
          orientation={orientation}
          className={cls(
            "shrink-0 bg-gray-300",
            orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
            className
          )}
          {...props}
        />
      );
    }

    return (
      <div className={cls("flex items-center", className)} {...props} ref={ref}>
        <div className="flex-grow border-t shrink-0 border-border"></div>
        {props.children && props.children}
        <div className="flex-grow border-t shrink-0 border-border"></div>
      </div>
    );
  }
);

export { Separator };
